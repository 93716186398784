<template>
  <v-row>
    <v-col cols="12">
      <v-alert v-show="copyAlert" type="info" text>
        {{ msgjsoncopiado }}
      </v-alert>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" top>
        {{ snackbar.message }}
      </v-snackbar>

      <base-card v-if="$store.state.invoice.listInvoice.Mensaje.length > 0">
        <v-card-title>
          Listado de Facturas Electrónicas
          <v-spacer />
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details />
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="$store.state.invoice.listInvoice.Mensaje" :search="search"
            :footer-props="{
              'items-per-page-text': 'filas por pagina',
            }" class="elevation-1">

            <template v-slot:[`item.sincronizar`]="{ item }">
              <v-row justify="center">
                <v-btn
                  v-if="item.sincronizar === 1"
                  color="green"
                  small
                  @click="synchronizeItem(item)"
                  icon
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </v-row>

              <v-dialog
                v-model="cardError.show"
                transition="dialog-bottom-transition"
                max-width="1000"
              >
                <v-card>
                  <v-toolbar color="primary" dark>
                    Sincronización de la factura - {{ cardError.invoiceNumber }}
                  </v-toolbar>
                  <v-card-text>
                    <v-row
                      class="fill-height"
                      align-content="center"
                      justify="center"
                    >
                    <div
                        class="text-h6 pa-4"
                        style="width: 90%; max-height: 400px; overflow-y: auto"
                      >
                        <json-viewer
                          v-if="cardError.response"
                          :value="cardError.response"
                          copyable="true"
                        >
                          <template v-slot:copy>
                            Copiar
                            <v-icon
                              style="cursor: pointer"
                              color="info darken-3"
                              class="mr-2"
                            >
                              mdi-content-copy
                            </v-icon>
                          </template>
                        </json-viewer>
                      </div>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="closeErrorCard">Cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.TotalMontoEncabezado`]="{ item }">
              <v-row justify="center">
                {{ item.TotalMontoEncabezado | currency }}

                <v-tooltip v-if="item.TotalMontoEncabezado > 0 && progressUnlockInvoice == false && isAdmin"  bottom >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small large color="orange darken-2" v-bind="attrs" v-on="on" @click="unlockInvoice(item)">
                      mdi-lock
                    </v-icon>
                  </template>
                  <span>Desbloquear</span>
                </v-tooltip>

              </v-row>
              <v-progress-linear v-if="progressUnlockInvoice == true" color="red darken-4" indeterminate rounded
                height="6" />
            </template>
            <template v-slot:[`item.Estado`]="{ item }">
              <v-tooltip v-if="item.Estado === 'W'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success darken-2" right class="mr-2" v-bind="attrs" v-on="on">
                    mdi-file-check
                  </v-icon>
                </template>
                <span>Procesado</span>
              </v-tooltip>
              <v-tooltip v-else-if="item.Estado === 'P'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red darken-2" right class="mr-2" v-bind="attrs" v-on="on">
                    mdi-file-alert
                  </v-icon>
                </template>
                <span>Pendiente</span>
              </v-tooltip>
              <v-tooltip v-else-if="item.Estado === 'E'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red darken-2" right class="mr-2" v-bind="attrs" v-on="on">
                    mdi-close-box
                  </v-icon>
                </template>
                <span>Pendiente</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.pdf`]="{ item }">
              <v-dialog v-if="item.Estado === 'W'" transition="dialog-bottom-transition" max-width="1500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" color="red darken-4" class="mr-2" v-bind="attrs" v-on="on"
                    @click="getDataPDF(item)">
                    mdi-file-pdf-box
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-actions class="justify-end">
                      <v-icon text @click="dialog.value = false">
                        mdi-close-thick
                      </v-icon>
                    </v-card-actions>
                    <v-toolbar style="
                        background-color: rgb(204 33 40);
                        color: white !important;
                      " dark>
                      PDF FACTURA {{ item.CosecutivoDocumento }}
                      <v-icon class="pa-4" @click="downloadPDF(item.CosecutivoDocumento)">
                        mdi-download-circle
                      </v-icon>
                    </v-toolbar>
                    <v-card-text>
                      <v-row v-if="progress == true" class="fill-height" align-content="center" justify="center">
                        <v-col cols="6">
                          <v-col class="text-subtitle-1 text-center" cols="12">
                            Consultando y Visualizando PDF
                          </v-col>
                          <v-progress-linear color="red darken-4" indeterminate rounded height="6" />
                        </v-col>
                      </v-row>
                      <div class="text-h6">
                        <h5 style="text-align: center">
                          <b>{{ msgPdf }}</b>
                        </h5>

                        <pdf v-for="i in numPages" :key="i" ref="myPdfComponent" :src="src" :page="i" />
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.reprocesarFactura`]="{ item }">
              <v-dialog v-if="item.Estado === 'P' || item.Estado === 'E'" transition="dialog-bottom-transition"
                max-width="700">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" color="success darken-4" class="mr-2" v-bind="attrs" v-on="on"
                    @click="reprocessInvoice(item)">
                    mdi-file-move
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="primary" dark>
                      PROCESAMIENTO DE FACTURA - {{ item.CosecutivoDocumento }}
                    </v-toolbar>
                    <v-card-text>
                      <v-row v-if="progress == true" class="fill-height" align-content="center" justify="center">
                        <v-col cols="6">
                          <v-col class="text-subtitle-1 text-center" cols="12">
                            Reprocesando factura . Por favor espere un momento
                          </v-col>
                          <v-progress-linear color="red darken-4" indeterminate rounded height="6" />
                        </v-col>
                      </v-row>
                      <div class="text-h6">
                        <json-viewer v-if="reponseReprocessData != ''" :value="reponseReprocessData" copyable="true">
                          <template v-if="progress == false" v-slot:copy>
                            Copiar
                            <v-icon style="cursor: pointer" color="info darken-3" class="mr-2">
                              mdi-content-copy
                            </v-icon>
                          </template>
                        </json-viewer>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false"> Cerrar </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.reenviarEmail`]="{ item }">
              <v-dialog v-if="item.Estado === 'W' && item.ProveedorTecnologico == 1" transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" color="orange darken-3" class="mr-2" v-bind="attrs" v-on="on"
                    @click="clearEmail()">
                    mdi-email-fast
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar style="
                        background-color: rgb(204 33 40);
                        color: white !important;
                      " dark>
                      ENVIAR CORREO DE FACTURA - {{ item.CosecutivoDocumento }}
                    </v-toolbar>
                    <v-card-text style="text-align: center; margin-top: 40px">
                      <v-col cols="12" sm="12">
                        <v-text-field ref="email" v-model="email" label="Correo" prepend-inner-icon="mdi-email"
                          clearable required />
                      </v-col>
                      {{ msgResponseEmail }}
                    </v-card-text>
                    <v-row v-if="progress == true" class="fill-height" align-content="center" justify="center">
                      <v-col cols="6">
                        <v-col class="text-subtitle-1 text-center" cols="12">
                          Enviando Correo
                        </v-col>
                        <v-progress-linear color="red darken-4" indeterminate rounded height="6" />
                      </v-col>
                    </v-row>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false"> Cerrar </v-btn>
                      <v-btn text style="
                          background-color: rgb(204 33 40);
                          color: white !important;
                        " @click="sendEmail(item)">
                        Enviar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.reglasDian`]="{ item }">
              <v-dialog v-if="item.Estado == 'P'" transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" color="info darken-3" class="mr-2" v-bind="attrs" v-on="on">
                    mdi-book-open
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="primary" dark>
                      REGLAS DIAN - FACTURA {{ item.CosecutivoDocumento }}
                    </v-toolbar>

                    <v-card-text style="text-align: center; margin-top: 40px">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>REGLAS DE VALIDACÍON</v-list-item-title>
                          {{ item.reglasValidacionDIAN }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            REGLAS DE NOTIFICACÍON
                          </v-list-item-title>
                          {{ item.reglasNotificacionDIAN }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false"> Cerrar </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.QR`]="{ item }">
              <v-dialog v-if="item.Estado == 'W' && item.QR != null" transition="dialog-bottom-transition"
                max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" color="black darken-3" class="mr-2" v-bind="attrs" v-on="on">
                    mdi-qrcode-scan
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar style="
                        background-color: rgb(204 33 40);
                        color: white !important;
                      " dark>
                      QR FACTURA ELECTRÓNICA - FACTURA
                      {{ item.CosecutivoDocumento }}
                    </v-toolbar>

                    <v-card-text style="text-align: center; margin-top: 40px">

                      <qrcode     :size="item.ProveedorTecnologico === 2 ? 280 : 300"
                                  :value="item.QR" />
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false"> Cerrar </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.Cufe`]="{ item }">
              <v-icon v-if="item.Estado == 'W'" v-clipboard:copy="item.Cufe" v-clipboard:success="onCopy"
                style="cursor: pointer" color="info darken-3" class="mr-2">
                mdi-content-copy
              </v-icon>
            </template>
            <template v-slot:[`item.xml`]="{ item }">
              <v-icon style="cursor: pointer" color="success darken-3" class="mr-2" v-bind="attrs" v-on="on"
                @click="downloadXML(item.xml, item.CosecutivoDocumento)">
                mdi-xml
              </v-icon>
            </template>
            <template v-slot:[`item.NombreTienda`]="{ item }">
              {{ item.NombreTienda }}
            </template>
            <template v-slot:[`item.ProveedorTecnologico`]="{ item }">
              {{ getNameProoveedorTecnogico(item.ProveedorTecnologico) }}
            </template>
            <template v-slot:[`item.JsonHKA`]="{ item }">
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" color="info darken-3" class="mr-2" v-bind="attrs" v-on="on">
                    mdi-code-json
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-actions class="justify-end">
                      <v-icon text @click="dialog.value = false">
                        mdi-close-thick
                      </v-icon>
                    </v-card-actions>
                    <v-toolbar color="primary" dark>
                      DATOS ENVIADOS EN FORMATO JSON - FACTURA
                      {{ item.CosecutivoDocumento }}
                    </v-toolbar>
                    <v-card-text>
                      <div class="text-h6">
                        <json-viewer :value="JSON.parse(item.JsonHKA)" copyable="true">
                          <template v-slot:copy>
                            Copiar
                            <v-icon style="cursor: pointer" color="info darken-3" class="mr-2">
                              mdi-content-copy
                            </v-icon>
                          </template>
                        </json-viewer>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false"> Cerrar </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import Qrcode from "v-qrcode/src";
import XmlViewer from "vue-xml-viewer";
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import { columnsRoles } from "@/data/columnsRoles";
import { mapState } from "vuex";
import {
  listTechnologyProvider
} from "@/data/listEnvironment";

export default {
  name: "ShowInvoices",
  components: {
    Qrcode,
    XmlViewer,
    pdf,
  },

  data() {
    return {
      isAdmin: false,
      listTechnologyProvider: listTechnologyProvider,
      email: "",
      progress: false,
      progressUnlockInvoice: false,
      src: "",
      msgPdf: "",
      reponseReprocessData: "",
      numPages: 0,
      page: 1,
      currentPage: 0,
      singleSelect: false,
      selected: [],
      search: "",
      copyAlert: false,
      msgjsoncopiado: "Copiado",
      msgResponseEmail: "",
      headers: columnsRoles,
      nameProveedorTecnologico: "",
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      cardError: {
        show: false,
        response: "",
        invoiceNumber: "",
      },
      selectedItem: {},
      showSyncDialog: false,
    };
  },
  computed: {
    ...mapState("invoice", [
      "listInvoice",
      "dataBase64",
      "responseReprocess",
      "responseEmail",
      "responseUnlockInvoice"
    ])

  },
  watch: {
    dataBase64() {
      this.msgPdf = "";
      if (this.dataBase64.PDF !== undefined && !this.dataBase64.Error) {
        this.previewPDF();
      } else {
        this.msgPdf = `El PDF aún no está disponible en ${this.nameProveedorTecnologico}, Inténtelo más tarde. `;
        this.progress = false;
      }
    },
    responseReprocess() {
      this.showReprocess();
    },
    responseEmail() {
      if (this.responseEmail !== false) {
        this.showResponseEmail();
      }
    },
    responseUnlockInvoice() {
      if(this.responseUnlockInvoice.length == 0){
        return;
      }else{
        this.progressUnlockInvoice = false;
        this.$store.dispatch("invoice/setResponseUnlockInvoiceData", []);
        this.$store.dispatch("invoice/setRunListInvoiceData", true);
      }
    },
  },
  created() {
      this.isAdmin = JSON.parse(atob(localStorage.getItem('uid'))).rol === 'Administrador'
   },
  methods: {
    showResponseEmail() {
      this.progress = false;
      this.msgResponseEmail = this.responseEmail.Mensaje;
      this.$store.dispatch("invoice/setResponseEmail", false);
    },
    showReprocess() {
      this.progress = false;
      this.reponseReprocessData = this.responseReprocess;
      if (
        this.responseReprocess.Facturas.length > 0 &&
        this.responseReprocess.Facturas[0].Respuesta.error
      ) {
        return false;
      }
      this.getListInvoice();
    },
    previewPDF() {
      this.progress = false;
      const database64 = "data:application/pdf;base64," + this.dataBase64.PDF;
      this.src = pdf.createLoadingTask({ url: database64, CMapReaderFactory });
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    onCopy(e) {
      this.generateAlertCopy();
    },
    generateAlertCopy() {
      this.copyAlert = true;
      setTimeout(() => {
        this.copyAlert = false;
      }, 2000);
    },
    async getListInvoice() {
      await this.$store.dispatch("invoice/getListInvoice");
    },
    async reprocessInvoice(item) {
      this.reponseReprocessData = "";
      this.progress = true;
      await this.$store.dispatch("invoice/reprocessInvoice", item);
    },
    async getDataPDF(item) {
      this.msgPdf = "";

      if (item.ProveedorTecnologico == 1) {
        this.nameProveedorTecnologico = this.getNameProoveedorTecnogico(
          item.ProveedorTecnologico
        );
        this.msgPdf = "";
        this.progress = true;
        this.src = "";
        await this.$store.dispatch("invoice/getDataPDF", item);
      } else {
          this.nameProveedorTecnologico = this.getNameProoveedorTecnogico(
            item.ProveedorTecnologico
          );
          this.msgPdf = "";
          this.progress = true;
          this.src = "";
          await this.$store.dispatch("invoice/getDataPDFEDN", item);
      }
    },
    downloadPDF(name) {
      const linkSource = "data:application/pdf;base64," + this.dataBase64.PDF;
      const downloadLink = document.createElement("a");
      const fileName = name + ".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    handleInvoiceError(item, response) {
      this.cardError = {
        show: true,
        response: response,
        invoiceNumber: item.CosecutivoDocumento,
      };
    },
    closeErrorCard() {
      this.cardError.show = false;
      this.cardError.response = "";
      this.cardError.invoiceNumber = "";
    },
    downloadXML(xmlContent, name) {

      const blob = new Blob([xmlContent], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name + '.xml';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    async unlockInvoice (item) {
        this.progressUnlockInvoice = true;
        await this.$store.dispatch("invoice/unlockInvoice", item);
    },
    async sendEmail(item) {
      this.msgResponseEmail = "";
      this.progress = true;
      item.email = this.email;
      await this.$store.dispatch("invoice/sendEmail", item);
    },
    clearEmail() {
      this.msgResponseEmail = "";
      this.email = "";
    },
    getNameProoveedorTecnogico(value) {
      return this.listTechnologyProvider.filter((item) => item.valueEnvironment == value)[0].textEnvironment;
    },
    async synchronizeItem(item) {
      const data = {
        IdCompania: item.IdCompania,
        CosecutivoDocumento: item.CosecutivoDocumento,
        fecha: new Date().toISOString().split("T")[0],
        enviarConFechaActual: 1,
        ProveedorTecnologico: item.ProveedorTecnologico
      };

      try {
        const response = await this.$store.dispatch(
          "invoice/sincronizar",
          data
        );

        const facturas = response?.Facturas || [];

        const errorEnFactura = facturas.some(
          (factura) => factura.Respuesta?.error === true
        );

        if (errorEnFactura) {
          this.handleInvoiceError(item, response);
        } else {
          this.snackbar = {
            show: true,
            message: "Sincronización exitosa",
            color: "success",
          };
        }
      } catch (error) {
        console.error("Error al sincronizar:", error);
        this.snackbar = {
          show: true,
          message: "Error en la sincronización",
          color: "error",
        };
      }
    },
  },
};
</script>

<style scoped></style>
